@font-face {
  font-family: Montserrat-Regular;
  src: url("./assets/fonts/Montserrat-Regular.otf");
}

@font-face {
  font-family: Montserrat-Bold;
  src: url("./assets/fonts/Montserrat-Bold.otf");
}

@font-face {
  font-family: Montserrat-Light;
  src: url("./assets/fonts/Montserrat-Light.otf");
}

@font-face {
  font-family: Montserrat-Medium;
  src: url("./assets/fonts/Montserrat-Medium.otf");
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url("./assets/fonts/Montserrat-SemiBold.otf");
}

/* karla-regular */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Karla-Regular.ttf');
}

/* karla-bold */
@font-face {
  font-family: 'Karla';
  font-weight: bold;
  src: url('./assets/fonts/Karla-Bold.ttf');
}

.App {
  text-align: center;
  font-family: Karla;
  height: 100vh;
}

.App::after {
  /*content: "";
  background: url("./assets/login_bg1.svg") center center no-repeat,
    url("./assets/bg_pattern.png") repeat;
  background-size: 700px auto, 120px auto;
  opacity: 0.4;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;*/
  background-color: red;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: #FFF;
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  padding: 5px 0;
  border-bottom: 1px solid var(--border-color);
  letter-spacing: -0.03em;
  color: #000000;
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  //transform: scale(1.05);
  //transition: 0.25s ease-out;
  //color: #2ec943;
}

.calendar .header .icon:first-of-type {
  //margin-left: 1em;
}

.calendar .header .icon:last-of-type {
 // margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  font-size: 12px;
  padding: 0.75em 0;
  border-bottom: 1px solid #000;
}

.calendar .body .cell {
  position: relative;
  height: 6em;
  border-right: 1px solid #000;
  overflow: hidden;
  cursor: pointer;
  background: transparent;
  transition: 0.25s ease-out;
  padding-top: 1.6em;
}

.calendar .body .cell:hover {
  background: transparent;
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  background: #C9C9C9;
}

.calendar .body .today {
  //color: #2ec943;
}

.calendar .body .selected .today {
  color: black;
}

.calendar .body .row {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

.calendar .body .row:last-child {
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 12px;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: #F4F4F4;
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover {
  background-color: rgb(244, 244, 244);
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.9;
  transition: 0.5s ease-in;
  color: #F4F4F4;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc((100% / 7) - 1px);
  width: calc((100% / 7) - 1px);
}

.reminders {
  margin-top: 20px;
  & .header {
    font-size: 18px;
    color: #000;
    text-align: center;
  }

  & .list-item {
    border-bottom: 1px solid #000;

    &:last-child {
      border-bottom: none;
    }
  }

  & .list-container {
    display: flex;
    flex-direction: row;

    & .title {
      font-size: 18px;
      color: #000;
      text-align: left;
    }

    & .actions {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
